import logoLink from '../resources/ber.png';
import companyLogoLink from '../resources/ber.png';
import footerLogoLink from '../resources/ber_emeye-t-inside_logo.svg';

export const cssVariables = {
    header_footer_seperator: '#78052D',
    panel_highlight: '#00A0B9',
    chat_background_right: '#80D0DC',
    chat_background_left: '#D2D5D7',

    active: '#00A0B9',
    inactive: '#E61900',
    text_on_active: '#fff',
    text_on_inactive: '#fff',

    dropdown_background: '#80D0DC',

    phonebook_dropdown: '#80D0DC',
    phonebook_dropdown_svg: '#000',

    primary_light: '#80D0DC',
    primary: '#00A0B9',
    text_on_primary: '#fff',
    text_on_primary_caller: 'rgb(255, 255, 255)',

    secondary: '#E61900',
    text_on_secondary: '#fff',
    focus_active:'#E61900',

    red: '#E61900',
    green: '#87B91E',

    connecting: '#00A0B9',
    connected: '#87B91E',
    aborted: '#E61900',

    font_color: 'black',
    link_color: '#00A0B9',

    font: 'Arial, Helvetica, sans-serif',
    font_size: '16px',
    font_weight: '400',

    border_radius: '20px',
    border_width: '2px',
    border_style: 'solid',
    border_radius_narrow: '10px',
    border_radius_super_narrow: '5px',
    border_width_nav: '8px',

    disabled: '#D2D5D7',

    gridbox_header_bg: '#788187',
    gridbox_header_text: '#fff',
    gridbox_padding: '8px',
    gridbox_header_padding: '8px',

    pointer: '#00A0B9',

    toggle_switch: 'rgb(255, 255, 255)',
};

export const logo = logoLink;
export const companyTitle = 'BER';
export const companyLogo = companyLogoLink;
export const footerLogo = footerLogoLink;
